import jsCookie from "js-cookie";

export function getParameterByName(name: string) {
    const url = window.location.href;
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default {
    mounted() {
        const referredBy = getParameterByName('br');
        if (referredBy) {
            const existing = jsCookie.get('referral');
            if (!existing) {
                jsCookie.set('referral', referredBy, { expires: 3650 });
            }
        }
    }
}