import { Contract } from 'ethers';
import { providers } from 'ethers';
import contractAddresses from "./contract-addresses.json";
import erc20 from "./erc20.json";
import classicDice from "./classic-dice.json";

export default class MyContract {
    provider: providers.JsonRpcProvider | providers.Web3Provider;
    contract: Contract;
    signer: providers.JsonRpcSigner;

    constructor(contract = 'Tickets', signerAddress: string | number = 0, selectedProvider = '', atAddress: string | null = null) {
        let contractData: any;

        if (contract == "ClassicDice") {
            contractData = classicDice;
        } else if (contract == "erc20" && atAddress) {
            contractData = { abi: erc20 };
        } else {
            contractData = (contractAddresses as any)["thunder_main"]["thundercore"][contract];
        }

        if (!selectedProvider) {
            throw "--->ERROR: does not provide selected provider";
        }
        const win: any = window;
        if (selectedProvider == 'web3') {
            this.provider = new providers.Web3Provider(win[selectedProvider].currentProvider);
        } else {
            this.provider = new providers.Web3Provider(win[selectedProvider]);
        }

        this.signer = this.provider.getSigner(signerAddress);

        this.provider.pollingInterval = 1000;
        this.contract = new Contract(atAddress ? atAddress : contractData.address, contractData.abi, this.signer);
    }
}