
import { Component, Vue } from "vue-property-decorator";
import detectReferralMixin from "../detect-referral-mixin";
import ContractMonitoring from "../contracts/contract-monitoring";
import { BigNumber, ContractTransaction, Transaction, utils } from "ethers";

import Big from "bignumber.js";
import MyContract from "@/contracts/my-contract";
import { parseEther } from "ethers/lib/utils";
import feeSetup from "@/lib/fee-setup";

Big.config({ EXPONENTIAL_AT: 256 });

const oldTokenAddress = "0xbb9a3CC287a4060B06257435906E0491305C112C";

function toEther(v: any, exp = 18) {
    return new Big(v.toString()).div(new Big(10).pow(exp));
}

type Frozen = {
    amount: BigNumber;
    paid: BigNumber;
};

@Component({
    mixins: [detectReferralMixin],
})
export default class Dividend2 extends Vue {
    monitor = new ContractMonitoring("SeriouslyV2");
    // wager for 1 SET
    price = toEther(1e18);
    totalFrozen = toEther(0);
    // how much SET the user have
    userBalance = toEther(0);
    // how much TT are in the contract
    contractBalance = toEther(0);
    userFrozen = toEther(0);
    loading = 0;
    balanceInterval: any = -1;
    totalCollectedDividend = toEther(0);
    totalClaimedDividend = toEther(0);

    old = {
        frozen: toEther(0),
        frozenMigrated: toEther(0),
        dividendClaimed: toEther(0),
        dividend: toEther(0),
        totalFrozen: toEther(0),
        set: toEther(0),
        setMigrated: toEther(0),
    };

    yesterdayStatistics: { period: Date; dividend: Big }[] = [];

    beforeDestroy() {
        this.monitor.clear();
        clearInterval(this.balanceInterval);
    }

    async mounted() {
        const monitor = new ContractMonitoring("SeriouslyV2");

        await this.getContractBalance();
        await this.getYesterdayData();

        // fetch TT Balance every 5 seconds..
        this.balanceInterval = setInterval(() => {
            this.getContractBalance();
        }, 5000);

        if (monitor.contract) {
            this.totalCollectedDividend = toEther(
                await monitor.contract.totalCollectedDividend()
            );
            this.totalClaimedDividend = toEther(
                await monitor.contract.totalClaimedDividend()
            );

            // get total frozen
            this.totalFrozen = toEther(await monitor.contract.totalFrozen());
            // get price
            this.price = toEther(await monitor.contract.price());
        }

        // get SET balance
        const getBalance = async () => {
            const account = this.$store.state.selectedAccount;
            if (account && monitor.contract) {
                this.userBalance = toEther(
                    await monitor.contract.balanceOf(account)
                );
            }
        };

        getBalance();

        // monitor SET balance
        monitor.listen(
            "Transfer",
            async (from: string, to: string, amount: BigNumber) => {
                const account = this.$store.state.selectedAccount;
                if (
                    account &&
                    (account == to || account == from) &&
                    monitor.contract
                ) {
                    getBalance();
                }
            }
        );

        // get frozen
        const getFrozen = async () => {
            const account = this.$store.state.selectedAccount;
            if (account && monitor.contract) {
                const frozen: { amount: BigNumber; paid: BigNumber } =
                    await monitor.contract.frozen(account);
                this.userFrozen = toEther(frozen.amount.toString());
            }
        };

        getFrozen();

        // monitor frozen
        monitor.listen(
            "Froze",
            async (
                who: string,
                locked: BigNumber,
                totalLocked: BigNumber,
                totalFrozen: BigNumber
            ) => {
                this.totalFrozen = toEther(totalFrozen);
                const account = this.$store.state.selectedAccount;
                if (account == who) {
                    getFrozen();
                }
            }
        );

        // monitor unfrozen
        monitor.listen(
            "UnFroze",
            async (
                who: string,
                released: BigNumber,
                totalFrozen: BigNumber
            ) => {
                this.totalFrozen = toEther(totalFrozen);
                const account = this.$store.state.selectedAccount;
                if (account == who) {
                    getFrozen();
                }
            }
        );
    }

    async getMigrationData() {
        try {
            ++this.loading;
            const account = this.$store.state.selectedAccount;

            const my = new MyContract(
                "SetMigrator",
                account,
                this.$store.state.selectedProvider
            );

            if (my.contract) {
                this.old = {
                    ...this.old,
                    set: toEther(await my.contract.getTokenOnOld()),
                    setMigrated: toEther(
                        await my.contract.setAmountMigrated(account)
                    ),
                    frozen: toEther(await my.contract.getFrozenOnOld()),
                    frozenMigrated: toEther(
                        await my.contract.frozenAmountMigrated(account)
                    ),
                    dividendClaimed: toEther(
                        await my.contract.oldDividendClaimed(account)
                    ),
                    dividend: toEther(await my.contract.dividend()),
                    totalFrozen: toEther(await my.contract.totalFrozen()),
                };
            }
        } catch (error) {
            console.log(error);
        } finally {
            --this.loading;
        }
    }

    //---------> MIGRATION
    async approvedSet() {
        try {
            ++this.loading;
            const account = this.$store.state.selectedAccount;
            const myNew = new MyContract(
                "SetMigrator",
                account,
                this.$store.state.selectedProvider
            );

            const my = new MyContract(
                "erc20",
                account,
                this.$store.state.selectedProvider,
                oldTokenAddress
            );

            if (my.contract && myNew.contract) {
                const allowance: BigNumber = await my.contract.allowance(
                    account,
                    myNew.contract.address
                );
                const balance: BigNumber = await my.contract.balanceOf(account);

                if (allowance.lt(balance)) {
                    alert(
                        "You need to allow the contract to spend your OLD set token first\nPlease confirm the spending confirmation"
                    );
                    const resp = await my.contract.approve(
                        myNew.contract.address,
                        parseEther("999999999999"),
                        { ...feeSetup() }
                    );
                    const tx = await resp.wait(1);
                    alert(
                        "Approved! now you can continue the migration process\nPlease confirm the token migration"
                    );
                    if (tx.status) return true;
                } else return true;
            }
        } catch (error) {
            const e = error as any;
            if (e.data && e.data.message) {
                return console.log(e.data);
            }
            console.log(e);
        } finally {
            --this.loading;
        }
    }

    async migrateSet() {
        try {
            ++this.loading;
            const account = this.$store.state.selectedAccount;
            const provider = this.$store.state.selectedProvider;

            const my = new MyContract("SetMigrator", account, provider);
            if (my.contract) {
                if ((await this.approvedSet()) === true) {
                    const r: ContractTransaction =
                        await my.contract.migrateToken({ ...feeSetup() });
                    await r.wait(1);
                    await this.getMigrationData();
                } else {
                    alert("Contract spending is not approved");
                }
            }
        } catch (error) {
            const e = error as any;
            if (e.data && e.data.message) {
                return alert(e.data.message);
            }
            alert(e.message);
        } finally {
            --this.loading;
        }
    }

    async migrateFrozen() {
        try {
            ++this.loading;
            const account = this.$store.state.selectedAccount;
            const provider = this.$store.state.selectedProvider;

            const my = new MyContract("SetMigrator", account, provider);
            if (my.contract) {
                const r: ContractTransaction =
                    await my.contract.migrateFrozen({ ...feeSetup() });
                const receipt = await r.wait(1);
                this.checkForClaimedTT(receipt as any);
                await this.getMigrationData();
            }
        } catch (error) {
            const e = error as any;
            if (e.data && e.data.message) {
                return alert(e.data.message);
            }
            alert(e.message);
        } finally {
            --this.loading;
        }
    }

    //------------------- END MIGRATION

    // TT balance on contract
    async getContractBalance() {
        try {
            const monitor = new ContractMonitoring("SeriouslyV2");
            if (monitor.provider && monitor.contract) {
                this.contractBalance = toEther(
                    await monitor.provider.getBalance(monitor.contract.address)
                );
            }
        } catch (error) {
            console.log("when fetching tt balance on contract", error.message);
        }
    }

    checkForClaimedTT(receipt: {
        events?: [{ event: string; args: {}; eventSignature: string }];
    }) {
        console.log(receipt);
        if (receipt.events) {
            const claimed = receipt.events.filter((v) => v.event == "Claimed");
            if (claimed.length > 0) {
                // const who: string = claimed[0].args as any [0];
                const amount: BigNumber = (claimed[0].args as any)[1];

                // notify the user..
                window.$notify(
                    `Claimed ${toEther(amount)
                        .dp(5)
                        .toFormat()}TT from dividend`,
                    "info"
                );
            }
        }
    }

    async freeze() {
        try {
            ++this.loading;
            const my = new MyContract(
                "SeriouslyV2",
                this.$store.state.selectedAccount,
                this.$store.state.selectedProvider
            );
            if (my.contract) {
                const frozen: Frozen = await my.contract.frozen(
                    this.$store.state.selectedAccount
                );
                const totalFrozen: BigNumber = await my.contract.totalFrozen();
                const tokenPerStake: BigNumber =
                    await my.contract.tokenPerStake();

                console.log(
                    toEther(frozen.amount).toFormat(),
                    toEther(totalFrozen).toFormat(),
                    tokenPerStake.toString()
                );

                const tx: ContractTransaction = await my.contract.freeze({ ...feeSetup() });
                this.checkForClaimedTT((await tx.wait(1)) as any);
            }
        } catch (error) {
            alert(error.message);
        } finally {
            --this.loading;
        }
    }

    async unfreeze() {
        try {
            ++this.loading;
            const my = new MyContract(
                "SeriouslyV2",
                this.$store.state.selectedAccount,
                this.$store.state.selectedProvider
            );
            if (my.contract) {
                // const frozen: Frozen = await my.contract.frozen(
                //     this.$store.state.selectedAccount
                // );
                // const totalFrozen: BigNumber = await my.contract.totalFrozen();
                // const tokenPerStake: BigNumber =
                //     await my.contract.tokenPerStake();

                // console.log(
                //     toEther(frozen.amount).toFormat(),
                //     toEther(totalFrozen).toFormat(),
                //     tokenPerStake.toString()
                // );

                const tx: ContractTransaction = await my.contract.unfreeze({ ...feeSetup() });
                this.checkForClaimedTT((await tx.wait(1)) as any);
            }
        } catch (error) {
            alert(error.message);
        } finally {
            --this.loading;
        }
    }

    async claimDividend() {
        try {
            ++this.loading;
            const my = new MyContract(
                "SeriouslyV2",
                this.$store.state.selectedAccount,
                this.$store.state.selectedProvider
            );
            if (my.contract) {
                // const frozen: Frozen = await my.contract.frozen(
                //     this.$store.state.selectedAccount
                // );
                // const totalFrozen: BigNumber = await my.contract.totalFrozen();
                // const tokenPerStake: BigNumber =
                //     await my.contract.tokenPerStake();

                // console.log(
                //     toEther(frozen.amount).toFormat(),
                //     toEther(totalFrozen).toFormat(),
                //     tokenPerStake.toString()
                // );

                const tx: ContractTransaction = await my.contract.claimDividend(
                    this.$store.state.selectedAccount,
                    { ...feeSetup() }
                );
                this.checkForClaimedTT((await tx.wait(1)) as any);
            }
        } catch (error) {
            alert(error.message);
        } finally {
            --this.loading;
        }
    }

    async getYesterdayData() {
        ++this.loading;

        try {
            this.yesterdayStatistics = [];
            const monitor = this.monitor;
            if (monitor && monitor.provider && monitor.contract) {
                const period: BigNumber = await monitor.contract.getPeriod(
                    0,
                    true
                );
                const yesterday = period.sub("86400");

                let cursor = yesterday;
                let i = 1;
                while (i <= 7) {
                    const dividend: BigNumber =
                        await monitor.contract.dividendValue(cursor);
                    ++i;

                    this.yesterdayStatistics.push({
                        period: new Date(
                            new Big(cursor.toString()).times(1000).toNumber()
                        ),
                        dividend: new Big(dividend.toString())
                            .div(1e18)
                            .decimalPlaces(4),
                    });

                    // next
                    cursor = cursor.sub("86400");
                }
            }
        } catch (error) {
            let msg;
            if (error.data && error.data.message) msg = error.data.message;
            else {
                msg = error.message || error.toString();
            }

            window.$notify(msg, "danger");
        } finally {
            --this.loading;
        }
    }
}
